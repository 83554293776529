import Download from "components/download"
import Layout from "components/layout"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"

export default function Indir({ data }) {
  return (
    <Layout>
      <SEO
        title="Çekiliş Programı - İndir"
        description="Tusunami Çekiliş Programının en son kararlı sürümünü hemen indirin"
        pathname="https://www.kodvizit.com/cekilis-programi/indir"
        datePublished="2017-02-09T21:56:34+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/cekilis-programi",
            name: "Çekiliş Programı",
          },
        ]}
      />
      <Download
        headerText="Tusunami Çekiliş Programı’nı İndirdiğiniz İçin Teşekkürler!"
        icon={data.cekilisIcon.childImageSharp.fixed}
        downloadHref="https://www.kodvizit.com/indir/tusunamicekilisprogrami4.0.exe"
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    cekilisIcon: file(relativePath: { eq: "icon/cekilisprogram128.png" }) {
      childImageSharp {
        fixed(width: 128, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

Indir.propTypes = {
  data: PropTypes.object,
}
