import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"

// TODO: otomatik indirmeyi başlatma özelliği de ekle.
export default function Download({
  headerText,
  icon,
  downloadHref,
  downloadText = "İndirmeyi Başlat",
}) {
  return (
    <Container
      className="text-center"
      style={{ paddingTop: 60, paddingBottom: 100 }}
    >
      <Img fixed={icon}></Img>
      <h1 className="font-weight-light mb-5">{headerText}</h1>
      <div className="text-muted mb-3" style={{ fontSize: 15 }}>
        Yüklemeniz birkaç saniye içerisinde başlayacaktır.
        <br />
        Otomatik olarak yükleme başlamazsa{" "}
        <a href={downloadHref} target="_blank" rel="noopener noreferrer">
          bu bağlantıyı
        </a>{" "}
        kullanabilirsiniz
      </div>
      <a
        href={downloadHref}
        role="button"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-success font-weight-bold"
      >
        {downloadText}
      </a>
      <iframe
        title="İndir"
        src={downloadHref}
        width="1"
        height="1"
        frameBorder="0"
      />
    </Container>
  )
}

Download.propTypes = {
  headerText: PropTypes.string,
  downloadHref: PropTypes.string,
  downloadText: PropTypes.string,
  icon: PropTypes.object,
}
